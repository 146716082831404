import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import Section from "../Section";
import Button from "../Button";

import { injectIntl } from 'gatsby-plugin-intl';

import IconMail from "../../img/icon-global.svg";

import PreviewCompatibleImage from "../PreviewCompatibleImage";
import styles from "./index.module.scss";

import securityStandardsPhoto from "../../img/About-WhatWeDo.jpg";



class WhatWeDo extends React.Component {
	render() {
		return (
			<Container className={styles.fixedContainer}>
				<div className={styles.imageWrapper}>
					<PreviewCompatibleImage
						imageInfo={{
							image: securityStandardsPhoto,
							alt: ""
						}}
						roundedCorners={true}
					/>
				</div>
	
            <div className={styles.partnershipWrapper}>
                <Section className={styles.securitySection}>
                <Row className={styles.badge}>
                    <Col>
                        <Row>
                            <Col
                                xs={12}
                                lg={true}
                                className={styles.headingColumn}
                            >
                                <div className={styles.headingWrapper}>
                                    <img
                                        src={IconMail}
                                        className={styles.icon}
                                        width="65"
                                        height="65"
                                        alt=""
                                    />
                                    <h1 className={styles.heading}>
                                         What We Do.
                                    </h1>
                                </div> 
                            </Col>
                         </Row>
                        <Row>   
                            <Col xs={12} lg={true}>
                                <p>
                                    We help you save money and time. We offer foreign exchange and global payments solutions for both corporate and private clients. Beyond that, you can take advantage of our multi-currency account options and treasury services to further reduce your spend and up your efficiency.
                                </p>
                                <Button
                                    href= "/services"
                                    color={"darkPurple"}
                                    className={styles.button}
                                >
                                    Learn More
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Section>
        </div>
        </Container>
		);
	}
}

export default injectIntl (WhatWeDo);