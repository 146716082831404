import React from "react";
import styles from "./index.module.scss";

const howWeDo = [
        {    
        
            id: 1,
            title: "Sign Up.",
            content: "You can sign up for free in just seconds. The moment you do, you’ll be contacted by a member of our team to help you identify your unique foreign exchange needs and goals. Once you’ve entered the necessary banking information and gotten a feel for which services best suit your needs, we’ll help you think critically about the most affordable and efficient way to move forward. "
        },
        {
            id: 2,
            title: "Strategize.",
            content: "If you're hoping to move ahead right away with a simple transaction, you can enter the amount you'd like to send and view the real-time exchange rate before confirming your transaction. Alternatively, if you have multi-faceted foreign exchange or global payments needs, we’ll collaborate with you on a strategy in fitting with your needs and goals."
        },
        { 
            id: 3,
            title: "Send.",
            content: "All that’s left to do is hit send. We securely store your information for added ease on all future transactions. Whether you’re sending, receiving, or simply storing funds in your multi-currency account, the process is completely seamless. Receipts and reports are available in just a few simple clicks. "
        },
        { 
            id: 4,
            title: "Save.",
            content: "You can efficiently navigate foreign exchange volatility while taking advantage of our highly competitive international payment processing rates."
        }
    ]


class HowWeDo extends React.Component {
render() {
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        {/* Work Content */}
                        <div className="work-content text-center">
                            <h2 className={styles.h2}>How We Do It.</h2>
                            <p className=" my-3 mt-sm-4 mb-sm-5">Whether you’re looking to quickly send money abroad or leverage our platform for your business, we can help. The steps are simple and our customer support staff are readily available if you need a hand. </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {howWeDo.map(item => {
                                return (
                            <div  className="col-12 col-md-6">
                            {/* Single Work */}
                            <div className="single-work text-center p-3">
                                <h3 className={styles.h3} >{item.title}</h3>
                                <p >{item.content}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
}

export default HowWeDo;