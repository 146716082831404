import React from "react";
import MotifColumnFull from "../../components/MotifColumnFull";


class AboutHeroCTA extends React.Component {
	render() {
		return (
            <div classNames='styles.container'> 
                <MotifColumnFull >
                    <h1>{this.props.headingText}</h1>
                    <p>
                        {this.props.description}
                    </p>
            </MotifColumnFull>
          </div>
		);
	}
}

export default AboutHeroCTA;
