import React from "react";
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl';

import HowWeDo from "../components/HowWeDo";
import Layout from "../components/Layout";
import Background from "../components/Background";
import Hero from "../components/Hero";
import Statistics from "../components/Statistics";
import Questions from "../components/Questions";
import AboutHeroCTA from '../components/AboutHeroCTA';
import BucketGroup from "../components/BucketGroup";
import Bucket from "../components/Bucket";
import WhatWeDo from "../components/WhatWeDo";
import BucketGroupSticky from "../components/BucketGroupSticky";
import BucketForTwoColumn from "../components/BucketForSticky";

import iconFullyHosted from "../img/IndustryExpertise.svg";
import iconAdminDash from "../img/MarketAgility.svg";
import iconCustomizable from "../img/RelationshipReliability.svg";

import Executive from "../img/Executive.svg";
import Investor from "../img/Investor.svg";
import Accountant from "../img/Accountant.svg";
import HomeOwner from "../img/HomeOwner.svg";

import styles from "./about/index.module.scss";

const AboutPage = ({data, intl}) => {

  return (
    <Layout>
      <Background themeType={"oanda"}> 
        <Hero className={styles.headerpadding}>
          <AboutHeroCTA
            headingText={intl.formatMessage({id: 'about.hero.title'})}
            description={intl.formatMessage({id: 'about.hero.text'})
            }
          />
        </Hero>
        <BucketGroup 
          headingText={intl.formatMessage({id: 'about.whoweare.title'})}
        >
          <Bucket
            imageURL={iconFullyHosted}
            title={intl.formatMessage({id: 'about.whoweare.first.title'})}
            description={intl.formatMessage({id: 'about.whoweare.first.text'})}
          />
          <Bucket
            imageURL={iconAdminDash}
            title={intl.formatMessage({id: 'about.whoweare.second.title'})}
            description={intl.formatMessage({id: 'about.whoweare.second.text'})}
          />
          <Bucket
            imageURL={iconCustomizable}
            title={intl.formatMessage({id: 'about.whoweare.third.title'})}
            description={intl.formatMessage({id: 'about.whoweare.third.text'})}
          />
        </BucketGroup>
        <WhatWeDo />
        <HowWeDo />  
        <BucketGroupSticky headingText=' Meet the Shift Connect Global Community.'>
          <BucketForTwoColumn
            imageURL={Executive}
            title= 'The Executive.'
            description= 'A number of our community members are business owners or executives. These types of clients use our services to buy goods internationally, pay staff and contractors abroad, receive payments in multiple currencies, and expand further into the global marketplace. We manage risk, reduce cost, and support holistic foreign exchange strategies.'
          />
          <BucketForTwoColumn
            imageURL={Accountant}
            title='The Accountant.'
            description= "Accountants can utilize our technology to automate forex procedures for added ease, reduced human error, accelerated efficiency, and smooth operations. Our team becomes their support system, and our tech becomes a reliable tool for them to lean on. "
          />
            <BucketForTwoColumn
            imageURL={Investor}
            title='The Investor.'
            description= "We have many clients who are active investors or who are looking to expand their investment portfolios. Large purchases from across borders can be costly and cumbersome, from vehicles to real estate. Our platform supports investors of this type in making payments with ease in a highly secure environment.
"

         />
          <BucketForTwoColumn
            imageURL={HomeOwner}
            title='The Homeowner'
            description= 'Another demographic we serve is individuals and families who own property abroad. From buying the property itself to paying bills, we help them manage their cross-currency fees without incurring losses in the face of currency fluctuations. On top of that, they can draw upon our multi-currency accounts to efficiently transfer and withdraw funds as they travel between their homes.'
          />
        </BucketGroupSticky> 
        <Statistics />
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(AboutPage);

export const query = graphql`
  query {
    testimonialsYaml(page: {eq: "about"}) {
      items {
        id
      }
    }
  }
`
