import React from "react";
import { Col } from "react-bootstrap";

import styles from "./index.module.scss";

class MotifColumnFull extends React.Component {
  render() {
    return (
      <Col xs={this.props.xs} lg={this.props.lg} className={styles.motifColumnFull}>
        {this.props.children}
      </Col>
    );
  }
}

MotifColumnFull.defaultProps = {
  xs: 12,
  lg: 12,
};

export default MotifColumnFull;